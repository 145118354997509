import React from "react"
import "react-lightbox-pack/dist/index.css"
import arrow from "../static/images/component-img/PlatformArrow.svg"
import Slider from "react-slick"
import "../static/style/componentStyle/LumiqLifeSlider.scss"
import BlogCard from "./blog-card"
import "../static/style/componentStyle/blogs.scss"
import "../static/style/componentStyle/gcp-blog-card-slider.scss"


const GcpBlogSlider = ({ blogData }) => {
    let sortedData;
    if (blogData) {
        const dataCopy = [...blogData];
        sortedData = dataCopy.sort((a, b) => {
            const dateA = new Date(a.attributes.date.replace(/,/g, ''));
            const dateB = new Date(b.attributes.date.replace(/,/g, ''));
            return dateB - dateA;
        });
    }
    console.log(sortedData,"aaaaa");

    sortedData = sortedData?.filter((blogItem) => blogItem.attributes.blogSubType === "GCPType");

    const lifeAtLumiqSlider = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (

        <Slider {...lifeAtLumiqSlider}>

            {sortedData?.map((val, idx) => (
                <div className="blog-holder" key={idx}>
                    <BlogCard val={val} key={idx} breadcrumbs={""} resourceUrl={"blogs"} />
                </div>
            ))}
        </Slider>

    )
}

export default GcpBlogSlider
