import React, { useEffect, useState, useRef } from "react"
import "../static/style/pageStyle/gcp-consulting-partner.scss"
import { graphql, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Cards from "../components/cards"
import "aos/dist/aos.css"
/*! purgecss start ignore */ import "aos/src/sass/aos.scss" /*! purgecss end ignore */
import Underline from "../components/underline"
import CardsMobile from "../components/cards-mobile"
import UnderlineButton from '../components/underlineButton'
import awsAward from "../static/images/aws-page/Banner.png"
import awsAwardmobile from "../static/images/aws-page/BannerMobile.png"
import BlackBtn from "../components/black-btn"
import CaseStudies from "../components/caseStudies"
// import { FetchedAwsPartnerPageData } from "../services/helperFunctions"
import { FetchedGcpPartnerPageData } from "../services/helperFunctions"
import Parser from "html-react-parser"
import { Link } from "gatsby"
import BannerHeader from "../components/bannerHeader"
import DownloadBtn from "../components/download-btn"
import { gql, useQuery } from "@apollo/client"
import Blogs from "../components/blogs"
import GcpBlogSlider from "../components/gcp-blog-card-slider"


const AwsConsultingPartner = props => {
    const data = useStaticQuery(
        graphql`
      {
        allGcpPage {
          nodes {
            gcpPage {
              journey {
                attributes {
                  dynamicCmp {
                    Impact
                    Problem
                    Solution
                    cardHeading
                    content
                    iconUrl
                    id
                    imageUrl
                    statement
                    story
                  }
                  header
                  subHeader
                  cmpName
                }
              }
            }
          }
        }
        allBlogPage {
            nodes {
              blogPage {
                journey {
                  attributes {
                    date
                    imageUrl
                    header
                    blogType
                    description
                    slug
                  }
                }
              }
            }
          }
      }
    `
    )
    // const [blogData, setBlogData] = useState();

    const {
        testimonialSlider,
        cardMap,
        cardData,
        cardClient,
        sections,
        cards,
        tabs,
        allCaseStudy,
    } = FetchedGcpPartnerPageData(data?.allGcpPage?.nodes[0]?.gcpPage?.journey);

    const blogData=data.allBlogPage?.nodes[0]?.blogPage.journey;

    console.log(data,sections, "hello");
    const [isNavBarOpen, setIsNavBarOpen] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [mobileActiveTab, setMobileActiveTab] = useState(false)

    const [dActiveTab, setDActiveTab] = useState(true)
    const [mdActiveTab, setMDActiveTab] = useState(false)
    const handleClick = header => {
        setActiveTab(header)
        setDActiveTab(!dActiveTab)
        if (activeTab === header) {
            setDActiveTab(!dActiveTab)
        }
    }
    const handleClickMobile = header => {
        // console.log(header, "header")
        setMobileActiveTab(header)
        setMDActiveTab(!mdActiveTab)
        if (mobileActiveTab === header) {
            setMDActiveTab(!mdActiveTab)
        }
    }
    const imgAlt = "enterprise ai"
    useEffect(() => {
        if (props?.location?.state) {
            const id = Object.values(props?.location?.state)
                .filter(
                    (value, index) =>
                        typeof value === "string" &&
                        index !== Object.keys(props?.location?.state).length - 1
                )
                .join("")
            if (id) {
                const partnersLocation = document.getElementById(id)
                if (partnersLocation) {
                    partnersLocation.scrollIntoView({ behavior: "smooth" })
                }
            }
        }
    }, [props?.location?.state])


    return (
        <div
            className={`gcp-consulting-partner ${isNavBarOpen ? "" : "no-scroll"}`}
        >
            <div className="page-content">
                {/* -------- Banner start -------- */}
                <div className="banner-wrapper">
                    <div className="page-wrapper">
                        <div className="page-banner">
                            <div className="banner-header">
                                <BannerHeader headingType={"bannerTitle"} header={sections[0]["section-00"].header} class="heading-lg" line={"two-line"} />
                            </div>
                            <div className="image-container-header ">
                                <div className="banner-image">
                                    <img src={`${process.env.STRAPI_URL + sections[0]["section-00"].dynamicCmp[0].imageUrl}`}alt={imgAlt} />
                                </div>
                            </div>
                            {/* <div className="image-container-header mobile-responsive-class">
                                <div className="banner-image">
                                    <img src={awsAwardmobile} alt={imgAlt} />
                                </div>
                            </div> */}
                            <div className="banner-header banner-header-link">
                                <a href='https://cloud.google.com/customers/hdfc-ergo' target="_blank" ><UnderlineButton text={"Click Here To Read More"} /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />
                {/* section-01   leaveraging google cloud  */}

                <div className="section-01">
                    <div className="page-wrapper">
                        <div className="head">
                            <h2>{sections[1]['section-01'].header}</h2>
                        </div>

                        <div className="body">
                            {sections[1]['section-01'].dynamicCmp.map((item, index) => (
                                <div className="body-child" key={index}>
                                    <h3> {item.cardHeading}</h3>
                                    <p>{item.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Underline />

                <div className="section-02">
                    <div className="page-wrapper ">
                        <div className="main-container">
                            <h2>{sections[2]["section-02"].header}</h2>
                            <p className="para-head">{Parser(sections[2]["section-02"].subHeader)}</p>
                            <div className="aws-content">
                                <div className="customer-data-solution-cards">
                                    {cards.map((val, idx) => (
                                        <div className="data-solution-card" key={idx}>
                                            <div className="data-solution-card-icon">
                                                <div className="data-icon">
                                                    <img src={`${process.env.STRAPI_URL + val.iconUrl}`} alt={imgAlt} />
                                                </div>
                                                <p>{Parser(val.cardHeading)}</p>
                                            </div>
                                            <div className="data-solution-card-details">
                                                <p>{Parser(val.content)}</p>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="data-download-card">
                                        <DownloadBtn
                                            text="Explore our Gen AI Offerings"
                                            pdf={"gcp_data_sheat.pdf"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />

                <div className="section-03">
                    <div className="page-wrapper ">
                        <div className="main-container">
                            <h2>{sections[3]["section-03"].header}</h2>
                        </div>

                        <div className="box">

                            {sections[3]['section-03'].dynamicCmp.map((item, index) => (
                                <div className="box-child" key={index}>
                                    <h3>{item.cardHeading}</h3>
                                    <p>{item.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Underline />
                {/* --------section 03 case studies -------- */}
                <div className="section-04">
                    <div className="page-wrapper">
                        <div className="main-container" id="caseStudies">
                            <BannerHeader header={'Case Studies'} class="heading-lg" line={"single-line"} />
                            <div className="aws-case-studies">
                                <div className="aws-left-details">
                                    <ul>
                                        {tabs.map((tab, idx) => (
                                            <>
                                                <li
                                                    onClick={() => handleClick(tab.id)}
                                                    className={`${activeTab === tab.id ? "active-tab-black" : ""
                                                        }`}
                                                >
                                                    <span>{`${idx + 1}`}</span>
                                                    <div>
                                                        <p>{tab.label}</p>
                                                    </div>
                                                </li>
                                                <div className="case-studies-mobile-resp">
                                                    {tab.id === activeTab && dActiveTab && (
                                                        <CaseStudies
                                                            data={allCaseStudy[activeTab]}
                                                            id={activeTab}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                                <div className="aws-left-details-mobile">
                                    <ul>
                                        {tabs.map((tab, idx) => (
                                            <>
                                                <li
                                                    onClick={() => handleClickMobile(tab.id)}
                                                    className={`${mobileActiveTab === tab.id &&
                                                        mdActiveTab &&
                                                        "active-tab-black"
                                                        }`}
                                                >
                                                    <div>
                                                        <p>{idx + 1}</p>
                                                        <p>{tab.label}</p>
                                                    </div>
                                                    {!(mdActiveTab && mobileActiveTab === tab.id) && (
                                                        <p className="read-more">Read More</p>
                                                    )}
                                                </li>
                                                <div
                                                    className="case-studies-mobile-resp"
                                                    onClick={() => handleClickMobile(tab.id)}
                                                >
                                                    {tab.id === mobileActiveTab && mdActiveTab && (
                                                        <CaseStudies
                                                            data={allCaseStudy[mobileActiveTab]}
                                                            id={mobileActiveTab}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                                <div className="aws-right-details">
                                    {tabs.map(
                                        tab =>
                                            tab.id === activeTab && (
                                                <CaseStudies
                                                    data={allCaseStudy[activeTab]}
                                                    id={activeTab}
                                                />
                                            )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Underline />

                {/* blogs   */}
                <div className="blog-section">
                    <div className="page-wrapper">
                        <div className="heading">
                            <h3>Blogs</h3>
                        </div>
                        <div className="gcp-slider">
                            <GcpBlogSlider blogData={blogData} />
                        </div>
                    </div>
                </div>

                <Underline />

                {/* --------section 04 end -------- */}
                {/* --------section 04 start Our clients trust us.  -------- */}
                <div className="section-07">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <BannerHeader header={'Our Success Stories'} class="heading-lg" line={"single-line"} />
                            <div className="card-container ">
                                <div className="testimonialDesktop" aria-label="financial slider">
                                    <Slider {...testimonialSlider}>
                                        {cardMap.map(i => {
                                            return <Cards key={i} cardData={cardData[i]} cardClient={cardClient[i]} />
                                        })}
                                    </Slider>
                                </div>
                            </div>
                            <div className="card-container-mobile ">
                                <div className="testimonialDesktop">
                                    {cardMap.map(i => {
                                        return <CardsMobile key={i} cardData={cardData[i]} cardClient={cardClient[i]} />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------section 04 end -------- */}

                <Underline />

                <div className="section-08">
                    <div className="page-wrapper">
                        <div className="content">
                            <h3 className="heading-md">
                                {sections[6]['section-06'].header}
                            </h3>
                            <div>
                                <Link to='/careers' state={'applyForAJob'} name="career"><BlackBtn text={"Connect With Us"} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Underline />
            </div>
        </div>
    )
}

export default AwsConsultingPartner
